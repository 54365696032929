/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import useTracking from 'components/Globals/Analytics';
import classnames from 'classnames';
import { GOOGLE_OLD_TRACKING_SERVICES, CATEGORIES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import useInputSearchTracking from 'components/Globals/Analytics/hooks/useInputSearchTracking';

import { useVisitsHistory } from 'utils/search/visitsHistory';
import { useDialogs } from 'utils/hooks/useDialogs';
// TODO: FB-25982 Remove dependency on InputAdornment from MUI
import InputAdornment from '@mui/material/InputAdornment';

import Typography from 'components/uiLibrary/Typography';
import AutocompleteDropDown from 'components/uiLibrary/FormInputs/AutocompleteDropDown';
import TextField from 'components/uiLibrary/TextField';
import LinkButton from 'components/uiLibrary/LinkButton';
import EntityCard from 'components/Globals/EntityCard';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import artistQueries from 'containers/Artist/queries';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import { useQuery } from 'utils/react-query';
import { TRACK_EVENTS, ARTIST_SEARCH_SELECT } from 'utils/tracking';
import { ENTITY_TYPE, TP, BASE_PAGE_ROUTES, SEARCH_ENTITY_TYPE_MAP } from 'constants/index';

import classes from './ArtistSearch.module.scss';

const TEXT = {
  LOGIN: 'login',
  RECENTLY_VISITED: 'recentlyVisited',
};

const ArtistSearch = ({ trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const recentlyVisitedHistory = useVisitsHistory();
  const { navigate, queryParams, basePath, permissions } = usePageContext();
  const { setIsLoginDialog } = useDialogs();
  const [inputValue, setInputValue] = useState('');
  const [query, setQuery] = useState('');
  const inputRef = useRef(null);
  const { sessionId, onFocus: onInputFocusForAnalytics, onBlur: onInputBlurForAnalytics } = useInputSearchTracking({
    ...trackingData,
    subComponent: SUB_COMPONENTS.INPUT,
  });

  const { data: artistResults } = useQuery(
    artistQueries.getArtistsSearch({
      filters: { query },
      queryConfig: { enabled: !!query },
    }),
  );

  const recentlyVisited = recentlyVisitedHistory
    ?.map(item => ({
      ...item,
      entityType: SEARCH_ENTITY_TYPE_MAP[item.type],
    }))
    ?.filter(item => item.entityType === ENTITY_TYPE.ARTIST)
    ?.slice(0, 5);

  const recentlyVisitedOptions = [{ text: TEXT.RECENTLY_VISITED }, ...recentlyVisited, { text: TEXT.LOGIN }];

  const debouncedSetInputValue = useMemo(() => debounce(setQuery, 300), []);

  useEffect(() => {
    if (query && artistResults?.data) {
      const mappedData = artistResults?.data?.map((item, searchResultPosition) => {
        const { id: entityId, name: entityName } = item || {};
        return {
          entityId,
          entityName,
          entityType: ENTITY_TYPE.ARTIST,
          searchResultPosition,
        };
      });
      track.search({
        ...trackingData,
        searchQuery: query,
        category: CATEGORIES.SEARCH,
        meta: {
          ...trackingData?.meta,
          searchSessionId: sessionId,
          results: mappedData,
        },
      });
    }
  }, [artistResults]);

  useEffect(() => {
    if (queryParams?.query) {
      setInputValue(queryParams.query);
    } else {
      setInputValue('');
    }
  }, [queryParams.query]);

  const handleSearchChange = (e, value) => {
    track.click(
      {
        name: TRACK_EVENTS.SEARCH,
        id: ARTIST_SEARCH_SELECT.SEARCH,
        query: value,
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );

    setQuery(value);
  };

  const handleEnterKey = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      navigate.to(
        navigate.getLinkProps({
          baseRoute: BASE_PAGE_ROUTES.ARTISTS,
          onlyLinkProps: true,
          queryParams: { query: e.target.value },
        }),
      );
    }
  };

  const handleResultClick = (event, result) => {
    event.preventDefault();
    event.stopPropagation();

    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        id: ARTIST_SEARCH_SELECT.SELECT,
        query: result?.name,
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );

    track.click({
      ...trackingData,
      entityId: result?.id,
      entityName: result?.name,
      entityType: ENTITY_TYPE.ARTIST,
      searchQuery: result?.name,
    });

    navigate.to(
      navigate.getLinkProps({
        entity: result,
        entityType: ENTITY_TYPE.ARTIST,
        onlyLinkProps: true,
      }),
    );
  };

  const handleLoginPopup = () => {
    setIsLoginDialog({ isOpen: true });
  };

  return (
    <div>
      <AutocompleteDropDown
        inputValue={inputValue}
        options={
          inputValue?.length
            ? artistResults?.data || []
            : (recentlyVisitedOptions?.length > 2 && recentlyVisitedOptions) || []
        }
        onInputChange={(event, value) => {
          setInputValue(value);
          debouncedSetInputValue(value);
        }}
        onFocus={onInputFocusForAnalytics}
        onBlur={onInputBlurForAnalytics}
        onChange={(e, value) => handleResultClick(e, value)}
        className={classnames(classes.artistSearchField, {
          [classes.artistSearchFieldIndex]: basePath === BASE_PAGE_ROUTES.ARTISTS,
        })}
        renderInput={params => (
          <TextField
            {...params}
            label={t(`${TP}.FN_ARTIST_NAME`)}
            variant="outlined"
            size="small"
            color="primary"
            ref={inputRef}
            onChange={handleSearchChange}
            onKeyDown={handleEnterKey}
            className={classes.textField}
            autoComplete="off"
            placeholder={t(`${TP}.m_SEARCHFORARTIST`)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="end">
                  <SpriteIcon icon="search" size={20} />
                </InputAdornment>
              ),
            }}
          />
        )}
        renderMenuListItem={(props, option) => {
          const artist = option?.entity || option;
          if (recentlyVisitedOptions?.length > 2 && option?.text === TEXT.RECENTLY_VISITED) {
            return (
              <Typography variant="p" className={classes.menuList__item_recent} weight="bold">
                {t(`${TP}.SRCH_RECENTLY_VISITED`)}
              </Typography>
            );
          }

          if (recentlyVisitedOptions?.length > 5 && !permissions?.isLoggedIn && option?.text === TEXT.LOGIN) {
            return (
              <li className={classes.menuList__item_custom}>
                <LinkButton
                  variant="text"
                  onClick={handleLoginPopup}
                  rightIcon={<SpriteIcon icon="chevron_right" size={20} className={classes.expandMore} />}
                  styles={{ root: classes.customLogin }}
                >
                  {t(`${TP}.FN_VIEW_MORE`)} ({t(`${TP}.m_LOGIN`)})
                </LinkButton>
              </li>
            );
          }

          return (
            <>
              {!option?.text && (
                <li className={classes.menuList__item} onClick={event => handleResultClick(event, artist)}>
                  <EntityCard
                    entityType={ENTITY_TYPE.ARTIST}
                    entity={artist}
                    typographyProps={{
                      name: {
                        truncate: true,
                      },
                      subtext: {
                        truncate: true,
                      },
                    }}
                    isLinkShallow={false}
                    className={classes.cardItem}
                    trackingData={trackingData}
                    skipClickTracking
                  />
                  <SpriteIcon icon="arrow-right" size={20} className={classes.arrowIcon} />
                </li>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default ArtistSearch;
