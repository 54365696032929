import dynamic from 'next/dynamic';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import { TP } from 'constants/index';

const IntermediateBanners = dynamic(() => import('components/Globals/Banners/IntermediateBanners'));
const ArtistManager = dynamic(() =>
  import('components/Globals/Banners/MainBackgroundBanners/ArtistManager/ArtistManager'),
);
const Casting = dynamic(() => import('components/Globals/Banners/MainBackgroundBanners/Casting/Casting'));
const SearchBg = dynamic(() => import('components/SearchBg'));

export const AD_IDENTIFIER_TYPE = {
  TAG: 't',
  ID: 'i',
};

export const AD_TAGS = {
  OPERABASE: 'operabase',
  ARTIST: 'artist',
  CASTING: 'casting',
};

export const AD_PLACEMENTS = {
  BANNER: 'banner',
  INTERSTITIAL: 'interstitial',
  VERTICAL: 'vertical',
};

export const CONFIGURATIONS = {
  OB01: {
    id: 'OB01',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      title: 'FN_CONVERSION_HOME_BANNER_1_TITLE',
      subTitle: 'FN_CONVERSION_HOME_BANNER_1_SUBTITLE',
      className: 'bannerOne',
      subComponent: SUB_COMPONENTS.ARTIST_MANAGER_BANNER,
    }),
  },
  OB02: {
    id: 'OB02',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      title: 'FN_CONVERSION_HOME_BANNER_2_TITLE',
      subTitle: 'FN_CONVERSION_HOME_BANNER_2_SUBTITLE',
      otherText: 'FN_CONVERSION_HOME_BANNER_2_OTHER',
      disclaimer: 'FN_CONVERSION_DISCLAIMER',
      className: 'bannerTwo',
      subComponent: SUB_COMPONENTS.ARTIST_MANAGER_BANNER,
    }),
  },
  OB03: {
    id: 'OB03',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      title: 'FN_CONVERSION_HOME_BANNER_2_TITLE',
      subTitle: 'FN_CONVERSION_HOME_BANNER_2_SUBTITLE',
      otherText: 'FN_CONVERSION_HOME_BANNER_3_OTHER',
      disclaimer: 'FN_CONVERSION_DISCLAIMER',
      className: 'bannerThree',
      subComponent: SUB_COMPONENTS.ARTIST_MANAGER_BANNER,
    }),
  },
  OB04: {
    id: 'OB04',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      title: 'FN_CONVERSION_HOME_BANNER_4_TITLE',
      subTitle: 'FN_CONVERSION_HOME_BANNER_4_SUBTITLE',
      otherText: 'FN_CONVERSION_HOME_BANNER_4_OTHER',
      className: 'bannerFour',
      subComponent: SUB_COMPONENTS.ARTIST_MANAGER_BANNER,
    }),
  },
  OB05: {
    id: 'OB05',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      title: 'FN_CONVERSION_HOME_BANNER_5_TITLE',
      subTitle: 'FN_CONVERSION_HOME_BANNER_2_SUBTITLE',
      otherText: 'FN_CONVERSION_HOME_BANNER_4_OTHER',
      className: 'bannerFive',
      hideShade: true,
      subComponent: SUB_COMPONENTS.ARTIST_MANAGER_BANNER,
    }),
  },
  OB06: {
    id: 'OB06',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      title: 'FN_CONVERSION_HOME_BANNER_4_TITLE',
      subTitle: 'FN_CONVERSION_HOME_BANNER_2_SUBTITLE',
      otherText: 'FN_CONVERSION_HOME_BANNER_4_OTHER',
      className: 'bannerSix',
      subComponent: SUB_COMPONENTS.ARTIST_MANAGER_BANNER,
    }),
  },
  OB07: {
    id: 'OB07',
    tags: [AD_TAGS.CASTING],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      type: 'casting',
      title: 'FN_CONVERSION_CASTING_BANNER_TITLE_1',
      className: 'castingBannerOne',
      subComponent: SUB_COMPONENTS.CASTING_TOOL_BANNER,
    }),
  },
  OB08: {
    id: 'OB08',
    tags: [AD_TAGS.CASTING],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      type: 'casting',
      title: 'FN_CONVERSION_CASTING_BANNER_TITLE_2',
      className: 'castingBannerTwo',
      subComponent: SUB_COMPONENTS.CASTING_TOOL_BANNER,
    }),
  },
  OB09: {
    id: 'OB09',
    tags: [AD_TAGS.CASTING],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      type: 'casting',
      title: 'FN_CONVERSION_CASTING_BANNER_TITLE_3',
      className: 'castingBannerThree',
      subComponent: SUB_COMPONENTS.CASTING_TOOL_BANNER,
    }),
  },
  OB010: {
    id: 'OB010',
    tags: [AD_TAGS.CASTING],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      type: 'casting',
      title: 'FN_CONVERSION_CASTING_BANNER_TITLE_4',
      className: 'castingBannerFour',
      subComponent: SUB_COMPONENTS.CASTING_TOOL_BANNER,
    }),
  },
  OB011: {
    id: 'OB011',
    tags: [AD_TAGS.CASTING],
    placements: [AD_PLACEMENTS.INTERSTITIAL],
    component: IntermediateBanners,
    renderConfig: () => ({
      type: 'casting',
      title: 'FN_CONVERSION_CASTING_BANNER_TITLE_5',
      className: 'castingBannerFive',
      subComponent: SUB_COMPONENTS.CASTING_TOOL_BANNER,
    }),
  },
  OB012: {
    id: 'OB012',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.BANNER],
    component: ArtistManager,
    renderConfig: ({ isTrialExhausted }) => ({
      title: {
        text: 'FN_CONVERSION_HOME_BANNER_1_TITLE',
      },
      subTitle: {
        text: 'FN_CONVERSION_HOME_BANNER_1_SUBTITLE',
      },
      imageUrl: {
        en:
          'https://public.operabase.com/images/conversionFlow/mainBanners/artist-banner-img-1-en_20240704T080742582Z.webp',
        de:
          'https://public.operabase.com/images/conversionFlow/mainBanners/de/artist-banner-img-1-de_20240704T075132727Z.webp',
      },
      imagePosition: 'right',
      imageWidth: 485,
      buttonText: isTrialExhausted ? 'CL_UPGRADE_TO_PREMIUM' : `${TP}.FN_CONVERSION_ARTIST_BTN_1`,
      subComponent: isTrialExhausted
        ? SUB_COMPONENTS.UPGRADE_TO_ARTIST_PREMIUM_CTA
        : SUB_COMPONENTS.CLAIM_YOUR_FREE_MONTH_CTA,
      className: 'bannerOne',
    }),
  },
  OB013: {
    id: 'OB013',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.BANNER],
    component: ArtistManager,
    renderConfig: ({ isTrialExhausted }) => ({
      title: {
        text: 'FN_CONVERSION_HOME_BANNER_2_TITLE',
      },
      subTitle: {
        text: 'FN_CONVERSION_HOME_BANNER_2_SUBTITLE',
      },
      otherText: {
        text: 'FN_CONVERSION_HOME_BANNER_2_OTHER',
      },
      imageUrl: {
        en:
          'https://public.operabase.com/images/conversionFlow/mainBanners/artist-banner-img-2-en_20240704T080754063Z.webp',
        de:
          'https://public.operabase.com/images/conversionFlow/mainBanners/de/artist-banner-img-2-de_20240704T075901471Z.webp',
      },
      imageWidth: 197,
      imagePosition: 'left',
      buttonText: isTrialExhausted ? 'CL_UPGRADE_TO_PREMIUM' : `${TP}.FN_CONVERSION_ARTIST_BTN_2`,
      subComponent: isTrialExhausted
        ? SUB_COMPONENTS.UPGRADE_TO_ARTIST_PREMIUM_CTA
        : SUB_COMPONENTS.FIRST_MONTH_FREE_CTA,
      disclaimer: 'FN_CONVERSION_DISCLAIMER',
      className: 'bannerTwo',
    }),
  },
  OB014: {
    id: 'OB014',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.BANNER],
    component: ArtistManager,
    renderConfig: ({ isTrialExhausted }) => ({
      title: {
        text: 'FN_CONVERSION_HOME_BANNER_2_TITLE',
      },
      subTitle: {
        text: 'FN_CONVERSION_HOME_BANNER_2_SUBTITLE',
      },
      otherText: {
        text: 'FN_CONVERSION_HOME_BANNER_3_OTHER',
      },
      imageUrl: {
        en:
          'https://public.operabase.com/images/conversionFlow/mainBanners/artist-banner-img-3-en_20240704T080833105Z.webp',
        de:
          'https://public.operabase.com/images/conversionFlow/mainBanners/de/artist-banner-img-3-de_20240704T075918579Z.webp',
      },
      imageWidth: 391,
      imagePosition: 'left',
      buttonText: isTrialExhausted ? 'CL_UPGRADE_TO_PREMIUM' : `${TP}.FN_CONVERSION_ARTIST_BTN_3`,
      subComponent: isTrialExhausted
        ? SUB_COMPONENTS.UPGRADE_TO_ARTIST_PREMIUM_CTA
        : SUB_COMPONENTS.TRY_FREE_FOR_1_MONTH_CTA,
      disclaimer: 'FN_CONVERSION_DISCLAIMER',
      className: 'bannerThree',
    }),
  },
  OB015: {
    id: 'OB015',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.BANNER],
    component: ArtistManager,
    renderConfig: ({ isTrialExhausted }) => ({
      title: {
        text: 'FN_CONVERSION_HOME_BANNER_4_TITLE',
      },
      subTitle: {
        text: 'FN_CONVERSION_HOME_BANNER_4_SUBTITLE',
      },
      otherText: {
        text: 'FN_CONVERSION_HOME_BANNER_4_OTHER',
      },
      imageUrl: {
        en:
          'https://public.operabase.com/images/conversionFlow/mainBanners/artist-banner-img-5-en_20240724T161719986Z.webp',
        de:
          'https://public.operabase.com/images/conversionFlow/mainBanners/de/artist-banner-img-5-de_20240724T161719986Z.webp',
      },
      imageWidth: 422,
      imagePosition: 'right',
      buttonText: isTrialExhausted ? 'CL_UPGRADE_TO_PREMIUM' : `${TP}.FN_CONVERSION_ARTIST_BTN_4`,
      subComponent: isTrialExhausted
        ? SUB_COMPONENTS.UPGRADE_TO_ARTIST_PREMIUM_CTA
        : SUB_COMPONENTS.TRY_NOW_FOR_FREE_CTA,
      className: 'bannerFour',
    }),
  },
  OB016: {
    id: 'OB016',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.BANNER],
    component: ArtistManager,
    renderConfig: ({ isTrialExhausted }) => ({
      title: {
        text: 'FN_CONVERSION_HOME_BANNER_5_TITLE',
      },
      subTitle: {
        text: 'FN_CONVERSION_HOME_BANNER_4_SUBTITLE',
      },
      otherText: {
        text: 'FN_CONVERSION_HOME_BANNER_4_OTHER',
      },
      imageUrl: {
        en:
          'https://public.operabase.com/images/conversionFlow/mainBanners/artist-banner-img-5-en_20240724T161719986Z.webp',
        de:
          'https://public.operabase.com/images/conversionFlow/mainBanners/de/artist-banner-img-5-de_20240724T161719986Z.webp',
      },
      imageWidth: 422,
      imagePosition: 'right',
      buttonText: isTrialExhausted ? 'CL_UPGRADE_TO_PREMIUM' : `${TP}.FN_CONVERSION_ARTIST_BTN_1`,
      subComponent: isTrialExhausted
        ? SUB_COMPONENTS.UPGRADE_TO_ARTIST_PREMIUM_CTA
        : SUB_COMPONENTS.CLAIM_YOUR_FREE_MONTH_CTA,
      className: 'bannerFive',
    }),
  },
  OB017: {
    id: 'OB017',
    tags: [AD_TAGS.ARTIST],
    placements: [AD_PLACEMENTS.BANNER],
    component: ArtistManager,
    renderConfig: ({ isTrialExhausted }) => ({
      title: {
        text: 'FN_CONVERSION_HOME_BANNER_6_TITLE',
        styles: { fontSize: 40, textTransform: 'uppercase' },
      },
      subTitle: {
        text: 'FN_CONVERSION_HOME_BANNER_2_SUBTITLE',
        styles: { fontSize: 18, textTransform: 'uppercase' },
      },
      otherText: {
        text: 'FN_CONVERSION_HOME_BANNER_4_OTHER',
        styles: { fontSize: 36, color: '#F26D28', textTransform: 'uppercase' },
      },
      imageUrl: {
        en:
          'https://public.operabase.com/images/conversionFlow/mainBanners/artist-banner-img-6-en_20240724T161719986Z.webp',
        de:
          'https://public.operabase.com/images/conversionFlow/mainBanners/de/artist-banner-img-6-de_20240724T161719986Z.webp',
      },
      imageWidth: 458,
      imagePosition: 'right',
      buttonText: isTrialExhausted ? 'CL_UPGRADE_TO_PREMIUM' : `${TP}.FN_CONVERSION_ARTIST_BTN_4`,
      subComponent: isTrialExhausted
        ? SUB_COMPONENTS.UPGRADE_TO_ARTIST_PREMIUM_CTA
        : SUB_COMPONENTS.TRY_NOW_FOR_FREE_CTA,
      className: 'bannerSix',
    }),
  },
  OB018: {
    id: 'OB018',
    tags: [AD_TAGS.CASTING],
    placements: [AD_PLACEMENTS.BANNER],
    component: Casting,
    renderConfig: () => ({
      type: AD_TAGS.CASTING,
      subTitle: 'FN_CONVERSION_CASTING_BANNER_TITLE_1',
      renderArtistInsights: true,
      subComponent: SUB_COMPONENTS.TRY_NOW_CTA,
    }),
  },
  OB019: {
    id: 'OB019',
    tags: [AD_TAGS.CASTING],
    placements: [AD_PLACEMENTS.BANNER],
    component: Casting,
    renderConfig: () => ({
      type: AD_TAGS.CASTING,
      subTitle: 'FN_CONVERSION_CASTING_BANNER_TITLE_2',
      renderArtistInsights: true,
    }),
  },
  OB020: {
    id: 'OB020',
    tags: [AD_TAGS.CASTING],
    placements: [AD_PLACEMENTS.BANNER],
    component: Casting,
    renderConfig: () => ({
      type: AD_TAGS.CASTING,
      subTitle: 'FN_CONVERSION_CASTING_BANNER_TITLE_3',
      renderArtistInsights: false,
    }),
  },
  OB021: {
    id: 'OB021',
    tags: [AD_TAGS.CASTING],
    placements: [AD_PLACEMENTS.BANNER],
    component: Casting,
    renderConfig: () => ({
      type: AD_TAGS.CASTING,
      subTitle: 'FN_CONVERSION_CASTING_BANNER_TITLE_4',
      renderArtistInsights: true,
    }),
  },
  OB022: {
    id: 'OB022',
    tags: [AD_TAGS.CASTING],
    placements: [AD_PLACEMENTS.BANNER],
    component: Casting,
    renderConfig: () => ({
      type: AD_TAGS.CASTING,
      subTitle: 'FN_CONVERSION_CASTING_BANNER_TITLE_5',
      renderArtistInsights: true,
    }),
  },
  OB023: {
    id: 'OB023',
    tags: [AD_TAGS.OPERABASE],
    placements: [AD_PLACEMENTS.BANNER],
    component: SearchBg,
  },
};

export const AD_IDS = Object.keys(CONFIGURATIONS).reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});
