import React, { useMemo } from 'react';
import classnames from 'classnames';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import ShareEntity from 'components/Globals/ShareEntity';
import CastingToolPromo from 'components/Globals/CastingToolPromo';
import { QuaternaryButton } from 'components/uiLibrary/LinkButton';

import queries from 'containers/Globals/queries';

import usePageContext from 'utils/hooks/usePageContext';
import { useQuery } from 'utils/react-query';

import { useTranslation } from 'src/i18n';
import { TP, BASE_PAGE_ROUTES } from 'constants/index';
import { COMPONENTS } from 'components/Globals/Analytics/constants';

import classes from './HeadingSection.module.scss';

const Actions = ({ trackingData }) => {
  const { basePath } = usePageContext();
  return (
    <>
      {basePath && <ShareEntity trackingData={{ ...trackingData, component: COMPONENTS.SHARE_CTA }} />}
      <CastingToolPromo asCTA trackingData={{ ...trackingData, component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA }} />
    </>
  );
};

const HeadingSection = ({ title, count, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { paths, queryParams, basePath, navigate } = usePageContext();
  const { data } = useQuery(queries.getHomeNumbers());
  const artistCount = data?.data?.artists?.total;

  const { h1Title } = useMemo(() => {
    const { page, letter, query } = queryParams;
    let mainText = '';

    if (paths?.length === 1) {
      if (Object.keys(queryParams)?.length > 0) {
        const lastPage = Math.ceil(count / 30);
        mainText =
          title && `${title} ${page ? ` - ${t(`${TP}.FN_PAGE`)} ${page} ${t(`${TP}.FN_OF`)} ${lastPage}` : ''}`;
      } else {
        mainText = title && ` ${title}`;
      }
    } else if (query || letter) {
      mainText = t(`${TP}.FN_SEARCH_RESULT_LISTING_PAGE_TITLE`, {
        query: query || letter,
        entityName: t(`${TP}.m_ARTISTS`),
      });
    } else {
      mainText = t(`${TP}.m_ARTISTS`);
    }

    return { h1Title: mainText };
  }, [paths?.length, queryParams, t, title]);

  return (
    <div className={classnames(classes.headingSection, { [classes.headingSectionIndexPage]: basePath?.length > 0 })}>
      <div className={classes.headingSection__title}>
        <Typography
          className={classes.headingSection__text}
          variant={!basePath ? 'h2' : 'h1'}
          size={!basePath ? 32 : 28}
          weight="bold"
        >
          {h1Title}
        </Typography>
        {queryParams?.query && (
          <QuaternaryButton
            variant="text"
            {...navigate.getLinkProps({
              baseRoute: BASE_PAGE_ROUTES.ARTISTS,
              onlyLinkProps: true,
            })}
            disableHover
            isLink
            rightIcon={<SpriteIcon icon="chevron_right" size={16} />}
            styles={{ root: classes.viewAllArtist }}
            size="small"
          >
            {t(`${TP}.VIEW_ALL`)} {t(`${TP}.m_ARTISTS`)}
          </QuaternaryButton>
        )}
        {!queryParams?.query && (
          <Typography variant="span" className={classes.headingSection__text_count} color="secondary">
            {count > 0 ? count : artistCount}
          </Typography>
        )}
      </div>
      <div className={classes.headingSection__actions}>
        <Actions trackingData={trackingData} />
      </div>
    </div>
  );
};

export default HeadingSection;
