/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useInView } from 'react-intersection-observer';
import SectionBlock from 'components/Globals/SectionBlock';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import PreviewCard from 'components/Globals/PreviewCard';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import AdvertisementRenderer from 'components/Globals/AdvertisementBlock/AdvertisementRenderer';
import ConversionFlowBanners from 'components/Globals/Banners/ConversionFlowBanners';
import { TertiaryButton } from 'components/uiLibrary/LinkButton';

import artistQueries from 'containers/Artist/queries';
import usePageContext from 'utils/hooks/usePageContext';
import { getAdvertisementConfigs, AD_IDENTIFIER_TYPE, AD_PLACEMENTS, AD_TAGS } from 'utils/advertisements';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useTranslation } from 'src/i18n';
import { useQuery, useInfiniteQuery } from 'utils/react-query';

import { BASE_PAGE_ROUTES, ENTITY_TYPE, TP } from 'constants/index';

import classes from './FeaturedArtistsNew.module.scss';

const ProfessionRowList = ({ profession, limit = 8, preload = false, trackingData, seeAllArtistTrackingData }) => {
  const { navigate, baseRoute, basePath, queryParams } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const containerRef = useRef(null);
  const query = queryParams?.page || queryParams?.letter;

  const filters = {
    ...(profession?.profession_id && { primary_profession_id: profession?.profession_id }),
  };

  const { data, isLoading } = useInfiniteQuery(
    artistQueries.getTrendingArtist({
      filters,
      limit,
      queryConfig: {
        select: response => response?.pages?.[0]?.data?.slice(0, limit),
      },
    }),
  );

  const linkProps = navigate.getLinkProps({
    entityType: ENTITY_TYPE.ARTIST,
    path: profession?.slug,
    onlyLinkProps: true,
  });

  if (isLoading) {
    return <Skeleton className={classes.skeleton} variant="rectangular" height={267} />;
  }

  if (!data?.length) {
    return null;
  }

  return (
    <SectionBlock
      linkProps={linkProps}
      enableSeeAll
      title={profession?.name}
      className={classes.professionRowList}
      count={profession?.total}
      {...(!baseRoute && { variant: 'h3' })}
      hideSeparator
      seeAllTrackingData={seeAllArtistTrackingData}
    >
      <div
        className={classnames(classes.professionRowList__container, {
          [classes.professionRowList__indexContainer]: basePath === BASE_PAGE_ROUTES.ARTISTS,
        })}
        ref={containerRef}
      >
        {data?.map(artist => (
          <PreviewCard
            key={artist.id}
            entity={artist}
            entityType={ENTITY_TYPE.ARTIST}
            primaryProfession={profession}
            preloadImage={preload}
            {...(!isMobile && { size: 'large' })}
            trackingData={{
              ...trackingData,
              searchQuery: query,
            }}
          />
        ))}
      </div>
    </SectionBlock>
  );
};

// TODO: FB-25982 Rename this component to FeaturedArtists
const FeaturedArtistsNew = ({ professionsLimit, artistsLimit, trackingData, seeAllArtistTrackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate, basePath } = usePageContext();
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [visibleItems, setVisibleItems] = useState(professionsLimit || 5);
  const { isMobile } = useDeviceTypeLayouts();
  const isScrolled = useScrollTrigger({ disableHysteresis: true, threshold: 1050 });

  const { data } = useQuery(artistQueries.getAllProfessions());
  const { randomizedProfessions } = data || {};

  useEffect(() => {
    setVisibleItems(prevItems => (inView ? prevItems + 5 : prevItems));
  }, [inView]);

  const adConfigs = useMemo(
    () =>
      getAdvertisementConfigs({
        identifiers: [AD_TAGS.ARTIST, AD_TAGS.CASTING],
        identifierType: AD_IDENTIFIER_TYPE.TAG,
        placement: AD_PLACEMENTS.INTERSTITIAL,
      }),
    [],
  );

  return (
    <div className={classnames(classes.featuredArtists, { [classes.featuredArtistsHome]: professionsLimit })}>
      {randomizedProfessions?.slice(0, visibleItems)?.map((profession, index) => (
        <React.Fragment key={index}>
          <ProfessionRowList
            profession={profession}
            limit={artistsLimit}
            preload={index === 0}
            trackingData={{ ...trackingData, meta: { section: profession.slug } }}
            seeAllArtistTrackingData={{ ...seeAllArtistTrackingData, meta: { section: profession.slug } }}
          />
          {basePath === BASE_PAGE_ROUTES.ARTISTS ? (
            <>
              {index === 0 && (
                <>
                  <div
                    className={classnames(classes.conFlowBannersArtist, {
                      [classes.conFlowBannersArtist__sticky]: isScrolled && isMobile,
                    })}
                  >
                    <ConversionFlowBanners />
                  </div>
                </>
              )}
              {index !== 0 && index % 3 === 0 && (
                <div className={classes.banner}>
                  <AdvertisementRenderer
                    config={adConfigs[(profession?.total + index) % adConfigs.length]}
                    section={trackingData?.section}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {index === 0 && (
                <div className={classes.conFlowBanners}>
                  <ConversionFlowBanners />
                </div>
              )}
              {((index !== 0 && index % 3 === 0) || (professionsLimit > 0 && index === visibleItems - 1)) && (
                <div className={classes.banner}>
                  <AdvertisementRenderer
                    config={adConfigs[(profession?.total + index) % adConfigs.length]}
                    section={trackingData?.section}
                    isSmall
                  />
                </div>
              )}
            </>
          )}
        </React.Fragment>
      ))}
      {!professionsLimit && randomizedProfessions?.length > visibleItems && <div ref={ref} />}
      {professionsLimit && (
        <TertiaryButton
          shape="rectangle"
          rightIcon={<SpriteIcon icon="chevron_right" className={classes.icon} />}
          variant="text"
          isLink
          {...navigate.getLinkProps({
            baseRoute: BASE_PAGE_ROUTES.ARTISTS,
            onlyLinkProps: true,
          })}
          styles={{ root: classes.viewMoreArtistsList }}
          trackingData={seeAllArtistTrackingData}
        >
          {t(`${TP}.FN_VIEW_ALL_ARTISTS`)}
        </TertiaryButton>
      )}
    </div>
  );
};

export default FeaturedArtistsNew;
