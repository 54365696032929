import React from 'react';
import PageWrapper from 'components/Globals/Layout/PageWrapper';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import { useBaseSeoData } from 'utils/seo/index';

import { BASE_PAGE_ROUTES } from 'constants/index';

import classes from './BaseWrapper.module.scss';

const BaseWrapper = ({ children }) => {
  const { basePath } = usePageContext();
  const { isMobile, isTablet } = useDeviceTypeLayouts();
  const seo = useBaseSeoData();
  const isHeaderStatic = basePath === BASE_PAGE_ROUTES.ARTISTS && !isMobile && !isTablet;

  return (
    <PageWrapper seo={seo} isHeaderStatic={isHeaderStatic}>
      <div className={classes.root}>{children}</div>
    </PageWrapper>
  );
};

export default BaseWrapper;
