import React, { useMemo, useEffect, useState } from 'react';
import classnames from 'classnames';
import useTracking from 'components/Globals/Analytics';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import AutocompleteDropDown from 'components/uiLibrary/FormInputs/AutocompleteDropDown';
import { GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';
import InputAdornment from '@mui/material/InputAdornment';

import artistQueries from 'containers/Artist/queries';

import { getSelectedProfession, sortProfessions } from 'utils/artists';
import usePageContext from 'utils/hooks/usePageContext';
import { useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';
import { TRACK_EVENTS, PROFESSION_SEARCH_SELECT } from 'utils/tracking';
import { TP, BASE_PAGE_ROUTES } from 'constants/index';

import ArtistSearch from '../../Display/ArtistSearch';

import classes from './ProfessionSelectSection.module.scss';

const ProfessionSelectSection = ({ showAllProfessions = false, className, artistSearchTrackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const { navigate, paths, queryParams, basePath } = usePageContext();
  const [selectedProfession, setSelectedProfession] = useState({});
  const [filteredProfessions, setFilteredProfessions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);

  const { data } = useQuery(artistQueries.getAllProfessions());
  const { professions, voiceTypes, instrumentTypes } = useMemo(
    () => ({
      professions: sortProfessions(data?.professions),
      voiceTypes: sortProfessions(data?.voiceTypes),
      instrumentTypes: sortProfessions(data?.instrumentTypes),
    }),
    [data],
  );

  const professionInfo = getSelectedProfession({ paths, professions, voiceTypes, instrumentTypes });
  const allProfessions = useMemo(() => [...professions, ...voiceTypes, ...instrumentTypes], [
    professions,
    voiceTypes,
    instrumentTypes,
  ]);

  useEffect(() => {
    if (paths?.length === 1 && professionInfo?.type) {
      setSelectedProfession({
        [professionInfo.type]: professionInfo?.name,
      });
      setInputValue(prevValues => ({
        ...prevValues,
        [professionInfo.type]: professionInfo?.name || '',
      }));
    } else {
      setSelectedProfession({});
      setInputValue({});
    }
  }, [paths, professionInfo?.name, professionInfo?.type]);

  useEffect(() => {
    setFilteredProfessions(allProfessions);
  }, [allProfessions]);

  const selectOptions = useMemo(
    () => [
      {
        type: 'voiceType',
        label: t(`${TP}.FILTER_VOICETYPES`),
        options: voiceTypes,
        placeholder: t(`${TP}.FN_SELECT_VOICE`),
      },
      {
        type: 'instrumentType',
        label: t(`${TP}.FN_INDUSTRY_PRO_INSTRUMENTALISTS_LABEL`),
        options: instrumentTypes,
        placeholder: t(`${TP}.FN_SELECT_INSTRUMENTALISTS`),
      },
      {
        type: 'profession',
        label: t(`${TP}.FILTER_PROFESSIONS`),
        options: professions,
        placeholder: t(`${TP}.FN_SELECT_PROFESSION`),
      },
    ],
    [t, professions, voiceTypes, instrumentTypes],
  );

  const handleEnterPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChange = (value, type) => {
    setSelectedProfession({
      [type]: value?.name,
    });

    handleClose();

    let selectedItem;
    if (showAllProfessions) {
      const { name } = value || {};
      selectedItem = allProfessions?.find(item => item?.name === name);
    } else {
      const selectedOption = selectOptions?.find(option => option?.type === type);
      if (selectedOption) {
        selectedItem = selectedOption?.options?.find(option => option?.name === value?.name);
      }
    }

    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        id: PROFESSION_SEARCH_SELECT.SELECT,
        query: selectedItem?.name,
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );

    const params = {
      ...(Number(queryParams?.page) > 0 && { page: 1 }),
    };

    navigate.to(
      navigate.getLinkProps({
        baseRoute: BASE_PAGE_ROUTES.ARTISTS,
        path: `${selectedItem?.slug}`,
        onlyLinkProps: true,
        ...(Object.keys(params).length > 0 && { queryParams: params }),
      }),
    );
  };

  const selectedAllProfessionOption = filteredProfessions.find(option => option.name === selectedProfession.profession);

  return (
    <div className={classnames(classes.pss, { [className]: !!className })}>
      <div
        className={classnames(classes.pss__artistSearch, {
          [classes.pss__artistSearchIndex]: basePath === BASE_PAGE_ROUTES.ARTISTS,
        })}
      >
        <ArtistSearch trackingData={artistSearchTrackingData} />
      </div>
      {showAllProfessions ? (
        <div className={classes.pss__searchProfession}>
          <AutocompleteDropDown
            open={open}
            options={filteredProfessions}
            value={selectedAllProfessionOption || null}
            onChange={(e, value) => handleSelectChange(value, 'profession')}
            filterOptions={(optionsList, state) => {
              const inputText = state.inputValue.trim().toLowerCase();
              return optionsList.filter(option => option.name.toLowerCase().includes(inputText));
            }}
            onInputChange={(e, value) => setInputValue(value)}
            onBlur={handleClose}
            label={t(`${TP}.FN_PROFESSION`)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SpriteIcon icon="expand_more" size={20} className={classes.pss__searchIcon} />
                </InputAdornment>
              ),
              style: {
                height: 40,
              },
              onKeyDown: handleEnterPress,
              onClick: handleOpen,
            }}
            InputLabelProps={{ shrink: true }}
            textFieldProps={{ placeholder: t(`${TP}.STEPPER_SELECT_PROFESSION`) }}
          />
        </div>
      ) : (
        <div className={classes.pss__selectProfession}>
          {selectOptions.map(({ type, label, options, placeholder }, index) => (
            <AutocompleteDropDown
              key={`${type}_${index}`}
              options={options}
              inputValue={inputValue[type] || ''}
              onChange={(e, value) => {
                handleSelectChange(value, type);
                setInputValue(value.name);
              }}
              onInputChange={(event, value) => {
                setInputValue(prevValues => ({
                  ...prevValues,
                  [type]: value,
                }));
              }}
              label={label}
              InputProps={{
                endAdornment: (
                  <>
                    {inputValue[type] ? (
                      <SpriteIcon
                        icon="close"
                        size={20}
                        className={classes.closeIcon}
                        onClick={() => {
                          setInputValue(prevValues => ({
                            ...prevValues,
                            [type]: '',
                          }));
                          setSelectedProfession({});
                        }}
                      />
                    ) : (
                      <SpriteIcon icon="expand_more" size={20} className={classes.expandMore} />
                    )}
                  </>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              textFieldProps={{
                placeholder,
                onClick: () => handleOpen(index),
                onKeyDown: handleEnterPress,
              }}
              className={classes.pss__searchProfession_multi}
              filterOptions={(optionsList, state) => {
                const inputText = state.inputValue.trim().toLowerCase();
                return optionsList.filter(option => option.name.toLowerCase().includes(inputText));
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProfessionSelectSection;
